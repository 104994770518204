<template>
  <td class="table__cell" :colspan="span">
		<slot></slot>
	</td>
</template>

<script>
export default {
  props: {
    span: String,
	},
}

</script>

<style lang="scss" scoped>
@import '@/assets/scss/breakpoints.scss';
.table__cell{
	width: 100%;
	display: block;
	padding: var(--table-cell-mobile-padding, var(--table-cell-padding));
	@include breakpoint(sm){
		width: initial;
		display: table-cell;
		border-top: var(--table-border-top);
		border-right: var(--table-border-right);
		border-bottom: var(--table-border-bottom);
		border-left: var(--table-border-left);
		padding: var(--table-cell-padding);
	}
}
</style>

<!-- Used in : HV, DS -->